"use client";

import { FeedbackWidgetButton } from "@/components/functional/feedback-widget-button";
import { ProfileSettings } from "@/components/functional/profile-settings";
import { ReturnIcon } from "@/components/icons/return-icon";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { type Subscriptions } from "@prisma/client";
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import Link from "next/link";
import { CreditBadge } from "../credit-badge";
import { useEffect, useState } from "react";
import type { AccountsV2 } from "@prisma/client";

export function AccountHeader({
  path,
  subscription,
  alwaysHideSidebar,
  hideSideBar,
  setHideSideBar,
}: {
  path: string;
  subscription: Subscriptions;
  alwaysHideSidebar: boolean;
  hideSideBar: boolean;
  setHideSideBar: (hideSideBar: boolean) => void;
}) {
  const slug = path?.split("/").pop();

  const [account, setAccount] = useState<AccountsV2 | null>(null);

  useEffect(() => {
    if (path?.startsWith("/dashboard/account")) {
      void fetch(`/api/account/details/${slug}`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          await res.json().then((data: AccountsV2) => {
            setAccount(data);
          });
        }
      });
    }
  }, [path, slug]);

  return (
    <div
      style={{
        maxWidth:
          hideSideBar || alwaysHideSidebar ? "100vw" : "calc(100vw - 180px)",
      }}
    >
      <Badge
        variant={"outline"}
        className={
          "pl-lg w-full h-12 border-r rounded-none flex bg-gray-100 justify-center items-center font-medium text-sm"
        }
      >
        {!alwaysHideSidebar ? (
          hideSideBar ? (
            <ChevronRightIcon
              className="cursor-pointer"
              onClick={() => setHideSideBar(false)}
            />
          ) : (
            <ChevronLeftIcon
              className="cursor-pointer"
              onClick={() => setHideSideBar(true)}
            />
          )
        ) : path ? (
          <Link
            href={"/dashboard/list/" + account?.listId + "?account=" + slug}
          >
            <Button
              variant={"outline"}
              className="flex flex-row gap-sm items-center"
              disabled={account === null}
            >
              <ReturnIcon />
              <span>Back</span>
            </Button>
          </Link>
        ) : (
          <div className="pl-2xl font-medium text-base">
            <span className="text-primary text-2xl not-italic font-extrabold leading-6 tracking-[-2px]">
              Lava
            </span>
            <span className="text-surface-brand text-2xl not-italic font-extrabold leading-6 tracking-[-2px]">
              Reach
            </span>
          </div>
        )}

        <div className={"ml-auto flex flex-row gap-lg items-center"}>
          <CreditBadge subscription={subscription} />
          <FeedbackWidgetButton />
          <ProfileSettings hidePersonal={false} />
        </div>
      </Badge>
    </div>
  );
}
