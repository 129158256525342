"use client";

import { FeedbackWidgetButton } from "@/components/functional/feedback-widget-button";
import { ProfileSettings } from "@/components/functional/profile-settings";
import { ReturnIcon } from "@/components/icons/return-icon";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { cn } from "@/lib/utils";
import { OrganizationSwitcher, SignOutButton } from "@clerk/nextjs";
import { dark } from "@clerk/themes";
import { type Subscriptions } from "@prisma/client";
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { CreditBadge } from "./credit-badge";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {}

export function Header({
  subscription,
  className,
  alwaysHideSidebar,
  hideSideBar,
  setHideSideBar,
}: HeaderProps & {
  subscription: Subscriptions;
  alwaysHideSidebar: boolean;
  hideSideBar: boolean;
  setHideSideBar: (hideSideBar: boolean) => void;
}) {
  const path = usePathname();

  return (
    <div
      style={{
        maxWidth:
          hideSideBar || alwaysHideSidebar ? "100vw" : "calc(100vw - 180px)",
      }}
    >
      <Badge
        variant={"outline"}
        className={cn(
          "pl-lg w-full h-12 border-r rounded-none flex bg-gray-100 justify-center items-center font-medium text-sm",
          className
        )}
      >
        {!alwaysHideSidebar ? (
          hideSideBar ? (
            <ChevronRightIcon
              className="cursor-pointer"
              onClick={() => setHideSideBar(false)}
            />
          ) : (
            <ChevronLeftIcon
              className="cursor-pointer"
              onClick={() => setHideSideBar(true)}
            />
          )
        ) : path &&
          path !== "/dashboard" &&
          !path.startsWith("/dashboard/lists") ? (
          <Link href={"/dashboard/lists"}>
            <Button
              variant={"outline"}
              className="flex flex-row gap-sm items-center"
            >
              <ReturnIcon />
              <span>Back To My Lists</span>
            </Button>
          </Link>
        ) : (
          <div className="pl-2xl font-medium text-base">
            <span className="text-primary text-2xl not-italic font-extrabold leading-6 tracking-[-2px]">
              Lava
            </span>
            <span className="text-surface-brand text-2xl not-italic font-extrabold leading-6 tracking-[-2px]">
              Reach
            </span>
          </div>
        )}

        <div className={"ml-auto flex flex-row gap-lg items-center"}>
          <CreditBadge subscription={subscription} />
          <FeedbackWidgetButton />
          <ProfileSettings hidePersonal={false} />
        </div>
      </Badge>
    </div>
  );
}

export function BillingHeader({ className }: HeaderProps) {
  return (
    <div className="py z-[100]" style={{ maxWidth: "100vw" }}>
      <Badge
        variant={"outline"}
        className={cn(
          "pl-12 w-full h-12 border-none bg-[#222129] rounded-none flex  justify-center items-center font-medium text-sm",
          className
        )}
      >
        <Label className="hidden md:flex text-white">
          <span>
            Please contact{" "}
            <a href="mailto:info@lavareach.com" className="inline underline">
              info@lavareach.com
            </a>{" "}
            for any issues, or join our{" "}
            <a
              href="https://discord.gg/Y8su3YCm3n"
              target="__blank"
              className="underline"
            >
              Discord
            </a>
          </span>
        </Label>

        <div className="ml-auto flex flex-row gap-sm">
          <OrganizationSwitcher
            appearance={{ baseTheme: dark }}
            hidePersonal={false}
            afterSelectOrganizationUrl={`/dashboard`}
            createOrganizationMode="modal"
            afterCreateOrganizationUrl="/dashboard"
          />
          <SignOutButton>
            <Button variant="outline" size="sm">
              Sign Out
            </Button>
          </SignOutButton>
        </div>
      </Badge>
    </div>
  );
}
