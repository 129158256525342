export const settings = [
  // {
  //   name: "Variables",
  //   href: "/dashboard/settings/list",
  // },
  {
    name: "Integrations",
    href: "/dashboard/settings/integrations",
  },
  {
    name: "Monitors (Beta)",
    href: "/dashboard/settings/monitors",
  },
  {
    name: "Emails and Inbox",
    href: "/dashboard/settings/email",
  },
  {
    name: "Notifications",
    href: "/dashboard/settings/notifications",
  },
  {
    name: "Templates",
    href: "/dashboard/templates",
  },
  {
    name: "Account",
    href: "/dashboard/settings/user-profile",
  },
  {
    name: "Team",
    href: "/dashboard/settings/organization-profile",
  },
  {
    name: "Billing",
    href: "/dashboard/settings/billing",
  },
];

export const simpleUISettings = [
  // {
  //   name: "Variables",
  //   href: "/dashboard/settings/list",
  // },
  {
    name: "Integrations",
    href: "/dashboard/settings/integrations",
  },
  {
    name: "Monitors (Beta)",
    href: "/dashboard/settings/monitors",
  },
  {
    name: "Notifications",
    href: "/dashboard/settings/notifications",
  },
  {
    name: "Account",
    href: "/dashboard/settings/user-profile",
  },
  {
    name: "Team",
    href: "/dashboard/settings/organization-profile",
  },
  {
    name: "Billing",
    href: "/dashboard/settings/billing",
  },
];
